@font-face {
  font-family: 'abel';
  src: url('/abelfonts/fonts/Abel-Regular.eot'); /* IE9 Compat Modes */
  src: url('/abelfonts/fonts/Abel-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/abelfonts/fonts/Abel-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/abelfonts/fonts/Abel-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('/abelfonts/fonts/Abel-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/abelfonts/fonts/Abel-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

/*@font-face {*/
/*  font-family: 'abel';*/
/*  src: url('/abelfonts/Abel-Regular.ttf');*/
/*}*/

body {
  margin: 0px;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*  sans-serif;*/
  /*font-family: 'Titillium Web', sans-serif !important;*/
  /*font-family: "source_sans_proregular", sans-serif;*/
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#viz {
  top:40px;
  padding-left: 1%;
  padding-right: 1%;
  margin-left: 0px;
  margin-right: 0px;
}

#leftcol {
  position: fixed;
  top: 40px;
  padding-left: 3vw;
  padding-right: 3vw;
  min-width: 45vmin;
  width: 100vh;
}

#rightcol {
  position: relative;
  top:40px;
  min-width: 30vw;
  height:100%;
}

@media (max-width: 992px){
  #viz {
    padding-left:3%;
    padding-right:0%;
  }
  #rightcol {
  }
  #leftcol {
    padding: 0px;
    position: relative;
    height: 80%;
    max-height: 70vh;
    max-width: 70vh;
    min-width: 30vw;
    min-height: 30vh;
  }
}

@media (max-width: 768px){
  #viz {
    padding-left:3%;
    padding-right:0%;
  }
  #rightcol {
    padding-top: 2vh;
    /*min-width: 16vw;*/
  }
  #leftcol {
    padding: 1%;
    position: relative;
    height: 100%;
    max-height: 70vw;
    max-width: 70vw;
    min-width: 70vw;
    min-height: 70vmin;
  }
}

@media (max-width: 576px){
  #viz {
    padding-left:3%;
    padding-right:0%;
  }
  #rightcol {
    padding-left: 7vw;
    /*padding-right: 5vw;*/
    /*zoom: 0.95;*/
    /*-moz-transform: scale(0.95);*/
    /*min-width: 70vw;*/
  }
  #leftcol {
    padding-left: 0%;
    padding-right: 2%;
    position: relative;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    min-width: 50vw;
    min-height: 50vh;
  }
}

@media (max-width: 399px){
  #viz {
    padding-left:3%;
    padding-right:0%;
  }
  #rightcol {
    padding-left: 5vw;
    /*padding-right: 5vw;*/
    zoom: 0.95;
    -moz-transform: scale(0.95);
    /*min-width: 70vw;*/
  }
  #leftcol {
    padding-left: 0%;
    padding-right: 2%;
    position: relative;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    min-width: 50vw;
    min-height: 50vh;
  }
}


/*@media (max-height: 768px){*/
/*  #rightcol {*/
/*    !*  !*position: relative;*!*!*/
/*    !*  top:calc(100vw + 40px);*!*/
/*    !*  !*height: 100vh;*!*!*/
/*  }*/
/*    #leftcol {*/
/*  !*    position: fixed;*!*/
/*  !*    top: 240px;*!*/
/*  !*    bottom: 0;*!*/
/*      width: 100vh;*/
/*      height: 100vw;*/
/*    }*/
/*}*/



#partitionSVG {
  /*width: 100% !important;*/
  /*width: 20%;*/
}


div {
  /*font-family: 'Titillium Web', sans-serif !important;*/
  /*font-family: "source_sans_proregular", sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*img[src*=".svg"] {*/
/*  width: 100%;*/
/*}*/

/*div.tooltip {*/
/*  position: absolute;*/
/*  text-align: left;*/
/*  !*width: 350px;*!*/
/*  !*height: 100px;*!*/
/*  padding: 2px;*/
/*  font: 12px sans-serif;*/
/*  background: black;*/
/*  color: white;*/
/*  border: 0px;*/
/*  border-radius: 3px;*/
/*  pointer-events: none;*/
/*}*/

.breadcrumb1 {
  padding: 2px;
  font-size: 0.8em;
  border: 0px;
  pointer-events: none;
}

.breadcrumb2 {
  padding: 2px;
  margin-left: 2px;
  font-size: 0.8em;
  font-weight: bold;
}

.selection { }

.spinner-border-sm {
  width: 0.6rem;
  height: 0.6rem;
  border-width: 0.1rem;
  vertical-align: -5%;
  margin-left: 0.5rem;
  /*vertical-align: baseline;*/
}




/* Layout context menu
------------ */

.d3-context-menu {
  position: absolute;
  min-width: 150px;
  z-index: 1200;
  zoom: 1;
}

.d3-context-menu ul,
.d3-context-menu ul li {
  margin: 0;
  padding: 0;
}

.d3-context-menu ul {
  list-style-type: none;
  cursor: default;
}

.d3-context-menu ul li {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

/*
	Disabled
*/

.d3-context-menu ul li.is-disabled,
.d3-context-menu ul li.is-disabled:hover {
  cursor: not-allowed;
}

/*
	Divider
*/

.d3-context-menu ul li.is-divider {
  padding: 0;
}

/* Theming
------------ */

.d3-context-menu-theme {
  background-color: #f2f2f2;
  border-radius: 4px;

  /*font-family: Arial, sans-serif;*/
  font-size: 0.7em;
  border: 1px solid #d4d4d4;
}

.d3-context-menu-theme ul {
  margin: 4px 0;
}

.d3-context-menu-theme ul li {
  padding: 4px 16px;
}

.d3-context-menu-theme ul li:hover {
  background-color: #464646;
  color: #fefefe;
}

/*
	Header
*/

.d3-context-menu-theme ul li.is-header,
.d3-context-menu-theme ul li.is-header:hover {
  background-color: #f2f2f2;
  color: #444;
  font-weight: bold;
  font-style: italic;
}

/*
	Disabled
*/

.d3-context-menu-theme ul li.is-disabled,
.d3-context-menu-theme ul li.is-disabled:hover {
  background-color: #f2f2f2;
  color: #888;
}

/*
	Divider
*/

.d3-context-menu-theme ul li.is-divider:hover {
  background-color: #f2f2f2;
}

.d3-context-menu-theme ul hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*
	Nested Menu
*/
.d3-context-menu-theme ul li.is-parent:after {
  border-left: 7px solid transparent;
  border-top: 7px solid red;
  content: "";
  height: 0;
  position: absolute;
  right: 8px;
  top: 35%;
  transform: rotate(45deg);
  width: 0;
}

.d3-context-menu-theme ul li.is-parent {
  padding-right: 20px;
  position: relative;
}

.d3-context-menu-theme ul.is-children {
  background-color: #f2f2f2;
  border: 1px solid #d4d4d4;
  color: black;
  display: none;
  left: 100%;
  margin: -5px 0;
  padding: 4px 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.d3-context-menu-theme li.is-parent:hover > ul.is-children {
  display: block;
}


/* The sidepanel menu */
.sidepanel {
  height: 600px; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  /*position: fixed; !* Stay in place *!*/
  z-index: 1200; /* Stay on top */
  opacity: 0.9;
  /*top: 0;*/
  bottom: 25px;
  left: 0;
  color: white;
  background-color: #111;
  overflow-x: hidden;
  padding-left: 0px;
  padding-top: 0px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
.sidepanel a {
  padding: 2px 2px 2px 20px;
  text-decoration: none;
  font-size: 0.9em;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidepanel a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidepanel .closebtn {
  position: relative;
  top: 0;
  right: 20px;
  font-size: 1.5em;
  margin-left: 90%;
}

/* Style the button that is used to open the sidepanel */
.openbtn {
  font-size: 0.75em;
  cursor: pointer;
  background-color: #fff;
  color: #d7d7d7;
  padding: 5px 5px;
  border: none;
}

.openbtn:hover {
  background-color: #eee;
}

.configurator-menu {
  margin: 8px 8px 8px 8px;
  font-size: 0.8em;
  color: white;
}

.text-right {
  padding-right: 0px;
}

h6 {
  padding-top: 18px;
}



/* The sidepanel menu */
.helppanel {
  display: inline-block;
  height: 0; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  /*position: fixed; !* Stay in place *!*/
  z-index: 1200; /* Stay on top */
  opacity: 0.9;
  /*top: 0;*/
  /*float: left;*/
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  bottom: 40px;
  left: 37px;
  /*font-size: larger;*/
  font-size: 1.2em;
  color: #1e1e1e;
  background-color: #f8f8f8;
  overflow-x: hidden;
  padding-left: 0px;
  padding-top: 0px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
.helppanel a {
  padding: 2px 0px 2px 2px;
  text-decoration: bold;
  font-size: 1.5em;
  color: #454545;
  /*display: block;*/
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.helppanel a:hover, a:active, a:focus, a:after {
  /*color: #000000;*/
  outline-color: white;
}

/* Position and style the close button (top right corner) */
.helppanel .closehelpbtn {
  padding: 2px 2px 2px 2px;
  position: relative;
  top: 0;
  /*float: right;*/
  font-size: 1.5em;
  font-weight: bolder;
  margin-left: 2%;
}

/* Style the button that is used to open the sidepanel */
.openhelpbtn_d {
  z-index: 1190;
  left: 10px;
  width: 50px;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #fff;
  color: #c5c5c5;
  padding: 5px 5px;
  border: none;
}

.openhelpbtn_m {
  left: 2px;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #fff;
  color: #c5c5c5;
  padding: 2px 2px;
  margin: 18px 0px 16px 0px;
  border: none !important;
}



.openhelpbtn_m:hover {
  background-color: #eee;
}

.openhelpbtn_d:hover {
  background-color: #eee;
  border: 0px solid red;
}

.openhelpbtn_d:visited {
  border: 0px solid red;
}

.openhelpbtn_d:active {
  border: 0px solid red;
}

.openhelpbtn_d:focus {
  border: 0px solid red;
}


.help-menu {
  margin: 8px 8px 8px 8px;
  font-size: 0.8em;
  color: #000000;
}

.helppanel img {
  margin-right: 20px;
}

/* double icon in helptext causes 3 lines of text, added some margin to display image as a block*/
.img-dbl {
  margin-bottom: 25px;
}

.credit {
  z-index: 1180;
  /*margin-left: calc(5px + 5vw);*/
  /*margin-left: calc(10% + 10vw);*/
  /*padding-left: 15%;*/
  /*padding-right: 2vw;*/
  /*padding-bottom: calc(1px + 0.1vw);*/
  font-size: calc(8px + 0.3vw);
  color: gray;
  background: white;
}

.credit a {
  color: dimgray;
  text-decoration: underline;
}