
/*svg {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*}*/

.node {
  pointer-events: all;
  cursor: pointer;
  z-index: 1000;
}

.node text {
  font: 0.62em;
  pointer-events: none;
  text-anchor: middle;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
}

line.link {
  fill: none;
  /*stroke: #9ecae1;*/
  stroke-width: 2px;
}

.node circle {
  cursor: pointer;
  /*stroke: #3182bd;*/
  stroke-width: 1px;
}
