.imgtitle {
    /*font-family: 'abel';*/
}


/* [1] The container */
.img-hover-zoom {
    /*height: 300px; !* [1.1] Set it as per your need *!*/
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.3);
}

.img:-moz-loading {
    visibility: hidden;
}

/* for compatibility with main page */
a:hover {
    color: #000000;
    text-decoration: none;
}


/* full view */
/* unvisited link */
a.fullview:link {
    color: #b0b0b0 !important;
    text-decoration: underline;
}

/* visited link */
a.fullview:visited {
    color: grey !important;
}

/* mouse over link */
a.fullview:hover {
    color: #e4e8d0 !important;
    text-decoration: underline;
}

/* selected link */
a.fullview:active {
    color: grey !important;
}


/* ---- grid ---- */
.grid {
    max-width: 100%;
    padding: 0px;
}
/* ---- grid-item ---- */
.grid-sizer{
    width: calc(33% - 5px);
}
.grid-item {
    float: left;
    padding: 7px;
}

.grid-item img {
    width: 150px;
}


/*!* Effect 4: fall perspective *!*/
/*.grid.effect-4 {*/
/*    perspective: 1300px;*/
/*}*/

/*.grid.effect-4 li {*/
/*    transform-style: preserve-3d;*/
/*}*/

/*.grid.effect-4 li.animate {*/
/*    transform: translateZ(400px) translateY(300px) rotateX(-90deg);*/
/*    animation: fallPerspective .8s ease-in-out forwards;*/
/*}*/

/*@keyframes fallPerspective {*/
/*    100% { transform: translateZ(0px) translateY(0px) rotateX(0deg); opacity: 1; }*/
/*}*/


.card-columns {
    column-count:8;
}

/*@media (min-width: 576px) {*/
/*    .card-columns {*/
/*        column-count: 3;*/
/*    }*/
/*}*/

/*@media (min-width: 768px) {*/
/*    .card-columns {*/
/*        column-count: 2;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .card-columns {*/
/*        column-count: 4;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .card-columns {*/
/*        column-count: 5;*/
/*    }*/
/*}*/


ul {
    padding: 0;
    list-style: none;
}

#GalleryGrid {
    height: 600px;
    width: 700px;
}



 /* Lazy loading */

.placeholder {
    background: #f8f8f8;
    height: 100%;
}


/*.fade-appear {*/
/*    opacity: 0.01;*/
/*}*/

/*.fade-appear.fade-appear-active {*/
/*    opacity: 1;*/
/*    transition: opacity .5s ease-in;*/
/*}*/
